import axios from "axios";

const TELEGRAM_BOT_TOKEN = "bot7655885676:AAHtEROFD1p5e2NHe7n8bCVxliu4zAlE-y4";
const MY_CHAT_ID = 1263777556;
const SEP_LINK_CHAT_ID = 723900627;
const SEP_TONG_OF_SEP_LINK_CHAT_ID = 7308872982;

const ChatIds = [SEP_TONG_OF_SEP_LINK_CHAT_ID, SEP_LINK_CHAT_ID, MY_CHAT_ID];

interface TelegramMessagePayload {
  chat_id: number;
  text: string;
  parse_mode?: string;
}

/**
 * Function to send a message to a Telegram chat.
 * @param message The message to send
 */
export const sendTelegramMessage = async (
  message: string
): Promise<boolean> => {
  const url = `https://api.telegram.org/${TELEGRAM_BOT_TOKEN}/sendMessage`;
  const payload: TelegramMessagePayload = {
    chat_id: MY_CHAT_ID,
    text: message,
    parse_mode: "Markdown", // Optional: use "HTML" if you want to use HTML formatting
  };

  try {
    const response = await Promise.all(
      ChatIds.map((chatId) => {
        return axios.post(url, { ...payload, chat_id: chatId });
      })
    );
    // const response = await axios.post(url, payload);
    console.log("Message sent:", response);
    return true;
  } catch (error: any) {
    console.error(
      "Error sending message:",
      error,
      error.response?.data || error.message
    );
    return false;
  }
};
